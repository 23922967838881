import React from "react";
import Carousel from "nuka-carousel";
import styled from "styled-components";
import Image from "./Image";
import * as Icon from "./Icon";

const banners = [
  "../images/banner/banner-01.jpg",
  "../images/banner/banner-02.jpg"
];

const bannersRWD = [
  "../images/banner/banner-01rwd.jpg",
  "../images/banner/banner-02.jpg"
];

class StyleSideControl extends React.Component {
  render() {
    let { Comp } = this.props;
    return <Comp size={50} color="black" />;
  }
}

class BannerSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileDevice: false
    };
  }

  componentDidMount() {
    let screenWidth = window.screen.width;
    if (screenWidth <= 499) {
      this.setState({ mobileDevice: true });
    }
  }

  render() {
    let { mobileDevice } = this.state;

    return (
      <Carousel
        renderBottomCenterControls={props => (
          <StyleDotPage>
            {Array.from(Array(props.slideCount).keys()).map(i => (
              <li
                key={i}
                onClick={() => props.goToSlide(i)}
                style={{ color: props.currentSlide === i ? "black" : "#5558" }}
              >
                &bull;
              </li>
            ))}
          </StyleDotPage>
        )}
        renderCenterLeftControls={({ previousSlide }) => (
          <StyleSideControl
            Comp={props => (
              <Icon.KeyboardArrowLeft {...props} onClick={previousSlide} />
            )}
          />
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <StyleSideControl
            Comp={props => (
              <Icon.KeyboardArrowRight {...props} onClick={nextSlide} />
            )}
          />
        )}
        style={{
          boxShadow: "1px 1px 10px -2px grey"
        }}
      >
        {!mobileDevice
          ? banners.map((b, i) => (
              <ImageWrapper key={"banner" + i}>
                <Image background src={b} />
              </ImageWrapper>
            ))
          : bannersRWD.map((b, i) => (
              <ImageWrapper key={"banner=rwd" + i}>
                <Image background src={b} />
              </ImageWrapper>
            ))}
      </Carousel>
    );
  }
}

const ImageWrapper = styled.div`
  width: 100%;
  height: 600px;
`;

const StyleDotPage = styled.ul`
  list-style: none;
  margin-bottom: 20px;
  & > li {
    display: inline;
    margin: 0 5px;
    cursor: default;
  }
`;

export default BannerSection;
