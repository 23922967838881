import React from "react";
import styled from "styled-components";
import AppConfig from "../Config";

const themeColor = AppConfig.global.themeColor;

class SectionTitle extends React.Component {
  render() {
    let { children } = this.props;
    return (
      <Wrapper>
        <div className="title">{children}</div>
        <div className="line"></div>
      </Wrapper>
    );
  }
}
const Wrapper = styled.div`
  width: 100%;
  margin: 30px 0;
  display: flex;
  align-items: center;
  color: ${themeColor};
  & > .title {
    /* flex:0 ;  */
    letter-spacing: 4px;
    margin-right: 20px;
    font-weight: 500;
  }
  & > .line {
    flex: 1;
    height: 1px;
    background-color: ${themeColor};
  }
`;
export default SectionTitle;
