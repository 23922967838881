import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Carousel from "nuka-carousel";
import ActionCreator from "../ActionCreator";
import AppConfig from "../Config/WantcardConfig";
import JsonParser from "../Utils/JsonParser";
import SectionTitle from "./SectionTitle";
import ProductGridItem from "./ProductGridItem/Wantcard";
import * as Icon from "./Icon/Wantcard";

const themeColor = AppConfig.global.themeColor;

class ProductPromotionSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemPerRow: 4,
      products: []
    };
  }

  componentDidMount() {
    this._fetchProducts();
    this._checkScreenSize();
    setTimeout(() => {
      //It's a hack to solve height problem https://github.com/FormidableLabs/nuka-carousel/issues/265
      window.dispatchEvent(new Event("resize"));
    }, 0);
    window.onresize = () => {
      this._checkScreenSize();
    };
  }

  render() {
    let { title } = this.props;
    let { itemPerRow } = this.state;

    return (
      <Wrapper>
        <SectionTitle>{title}</SectionTitle>
        <div className="section">
          <Carousel
            slideWidth={itemPerRow === 1 ? 0.66 : 1}
            renderCenterLeftControls={({ previousSlide }) =>
              itemPerRow === 1 ? null : (
                <StyleSideControl
                  Comp={props => (
                    <Icon.KeyboardArrowLeft
                      {...props}
                      onClick={previousSlide}
                    />
                  )}
                />
              )
            }
            renderCenterRightControls={({ nextSlide }) =>
              itemPerRow === 1 ? null : (
                <StyleSideControl
                  Comp={props => (
                    <Icon.KeyboardArrowRight {...props} onClick={nextSlide} />
                  )}
                />
              )
            }
            renderBottomCenterControls={() => null}
            style={{
              padding: "0 40px",
              height: 350
            }}
          >
            {this._reconstructItem().map((onePage, i) => (
              <OnePage key={"onePage" + i}>{onePage}</OnePage>
            ))}
          </Carousel>
        </div>
      </Wrapper>
    );
  }
  _fetchProducts = async () => {
    let { appActions, type, itemId = 0 } = this.props;

    appActions
      .fetchProducts({ limit: 100 })
      .then(resp => {
        let products = resp.results;

        let hitProducts = products.filter(p => {
          if (p.label) {
            let labelsArray = JsonParser(p.label);
            if (labelsArray) {
              let hitLabel = labelsArray.filter(l => l === type);
              if (hitLabel.length > 0) {
                if (itemId === p.id) {
                  return false;
                }
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          }
        });

        this.setState({ products: hitProducts });
      })
      .catch(err => console.warn(err));
  };

  _checkScreenSize = () => {
    let { itemPerRow } = this.state;
    if (window.matchMedia("(min-width:1024px)").matches) {
      itemPerRow !== 4 && this.setState({ itemPerRow: 4 });
    } else if (window.matchMedia("(min-width:768px)").matches) {
      itemPerRow !== 3 && this.setState({ itemPerRow: 3 });
    } else if (window.matchMedia("(min-width:500px)").matches) {
      itemPerRow !== 2 && this.setState({ itemPerRow: 2 });
    } else {
      itemPerRow !== 1 && this.setState({ itemPerRow: 1 });
    }
  };

  _reconstructItem = () => {
    let { navActions } = this.props;
    let { itemPerRow, products } = this.state;
    let result = [];
    products.forEach((product, idx, array) => {
      let e = (
        <ProductGridItem
          key={idx}
          item={product}
          onClick={() => navActions.push(`/product/?id=${product.id}`)}
        />
      );
      if (idx % itemPerRow === 0) {
        result.push([e]);
      } else {
        let pushIndex = result.length - 1;
        result[pushIndex].push(e);
      }
    });

    return result;
  };
}

const Wrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  & > .section {
    margin: 0 -40px;
    margin-bottom: 60px;
  }

  @media screen and (max-width: 500px) {
    width: 90%;
  }
`;

const OnePage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-height: 350px;

  :focus {
    outline: none;
  }
`;

class StyleSideControl extends React.Component {
  render() {
    let { Comp } = this.props;
    return (
      <Comp
        size={30}
        color={themeColor}
        css={`
          border: solid 1px ${themeColor};
          background-color: white;
          border-radius: 20px;
        `}
      />
    );
  }
}
export default connect(
  null,
  ActionCreator
)(ProductPromotionSection);
