import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import Image from "./Image";

class CollectionItem extends Component {
  render() {
    let { country, navActions } = this.props;
    return (
      <Wrapper
        onClick={() => navActions.push(`products/simCard-${country.path}`)}
      >
        <Image background src={country.image} />
        <div className="text">
          <div className="title">{"  "}</div>
          {/* country.name_en */}
          <div className="line" />
          <div className="subtitle">{country.name}</div>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  cursor: pointer;
  position: relative;
  width: 45%;
  height: 220px;
  border: solid 5px white;
  box-shadow: 1px 1px 8px -4px grey;
  margin-bottom: 30px;

  &:active {
    box-shadow: none;
  }

  & > .text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-shadow: 1px 1px 4px #373737;
    & > .title {
      font-size: 4.5em;
      white-space: pre;
      margin: 10px 0;
    }
    & > .line {
      height: 3px;
      width: 70px;
      background-color: #f8b82a;
    }
    & > .subtitle {
      letter-spacing: 2px;
      margin-top: 15px;
    }
  }

  @media screen and (max-width: 500px) {
    width: 90%;
    margin-bottom: 10px;
  }
`;

export default connect(
  null,
  ActionCreator
)(CollectionItem);
