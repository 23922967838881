import React from "react";
import SectionTitle from "./SectionTitle";
import styled from "styled-components";
import CollectionItem from "../Components/CollectionItem";

class CollectionSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collection: [
        {
          name: "日本",
          name_en: "Japan",
          image: "../images/country/Wantcard_Japan_5days.jpg",
          path: "japan"
        },
        {
          name: "香港",
          name_en: "Hong Kong",
          image: "../images/country/Wantcard_HongKong_5days.jpg",
          path: "hongkong"
        },
        {
          name: "泰國",
          name_en: "Thailand",
          image: "../images/country/Wantcard_Thailand_5days.jpg",
          path: "thailand"
        },
        {
          name: "菲律賓",
          name_en: "Philippines",
          image: "../images/country/Wantcard_Philippines_5days.jpg",
          path: "philippines"
        }
      ]
    };
  }
  render() {
    let { collection } = this.state;
    return (
      <Wrapper>
        <SectionTitle>享遊精選</SectionTitle>
        <div className="section">
          {collection.map(country => (
            <CollectionItem key={country.name} country={country} />
          ))}
        </div>
      </Wrapper>
    );
  }
}
const Wrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  & > .section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  @media screen and (max-width: 500px) {
    width: 90%;
  }
`;
export default CollectionSection;
