import React from "react";
import styled from "styled-components";
import { withPage } from "../../Page";
import AppConfig from "../../Config";
import BannerSection from "../../Components/BannerSection";
import CollectionSection from "../../Components/CollectionSection";
import ProductPromotionSection from "../../Components/ProductPromotionSection";

class HomePage extends React.Component {
  render() {
    let config = AppConfig["Templates/LandingPage"];
    return (
      <Wrapper>
        <BannerSection />
        <CollectionSection />
        <ProductPromotionSection title="熱門商品" type="hotSale" />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  background-color: white;
  ${AppConfig["Templates/LandingPage"].css || ""};
`;

export default withPage(HomePage);
